import React, { useEffect, useRef, useState } from "react"
import { ProductItem } from "../../models/product-item"
import "./product-details.css"
import { analytics } from "../../utils/firebase"
import SEO from "../../components/seo/seo"
import SuggestedProducts from "./suggested-products"
import Footer from "../../components/footer/footer"
import ReactPixel from "react-facebook-pixel"
import { Item, logEvent } from "firebase/analytics"
import Helmet from "react-helmet"
import { helmetJsonLdProp } from "react-schemaorg"
import { Product, WithContext } from "schema-dts"
import ProductReviews from "./product-reviews"
import ProductFeaturedIngredients from "./product-featured-ingredients"
import ProductHowToUse from "./product-how-to-use"
import ProductDefaultTop from "./product-default-top"
import ProductGiftingTop from "./product-gifting-top"

const ProductDetails = ({ pageContext: productContext, transitionStatus }) => {

    let product: ProductItem = new ProductItem(productContext)

    useEffect(() => {
        let items: Item[] = []
        items.push(product.toDataLayer())
        logEvent(analytics, "view_item",
            {
                items: items
            })

        ReactPixel.track("ViewContent", product.toFacebookEvent())

        let _learnq = window["_learnq"] || []
        _learnq.push(["trackViewedItem", product.toKlaviyoRecentItem()])
        _learnq.push(["track", "Viewed Product", product.toKlaviyoItem()])

    }, [])

    const [size, setSize] = useState("")

    useEffect(() => {
        if (document.documentElement.clientWidth < 824) {
            setSize("small")
        } else {
            setSize("large")
        }
    }, [])

    let reviewsSection = useRef<HTMLDivElement>(null)

    // const [scrollPastReviews, setScrollPastReviews] = useState(true);
    // const [scrollPastStart, setScrollPastStart] = useState(false);

    // useEffect(() => {
    //     if (reviewsSection?.current) {
            // window.addEventListener("scroll", updateScroll)
        // }
        //
        // return () => {
            // window.removeEventListener("scroll", updateScroll)
        // }
    //
    // }, [reviewsSection?.current?.offsetTop])

    // const updateScroll = () => {
    //     if (window.scrollY > ((reviewsSection?.current?.offsetTop ?? 0) - 112)) {
    //         // setScrollPastReviews(true)
    //     } else {
    //         // setScrollPastReviews(false)
    //     }
    //     if (window.scrollY > 20) {
    //         // setScrollPastStart(true)
    //     } else {
    //         // setScrollPastStart(false)
    //     }
    // }


    return (
        <>
            <SEO description={product.seo.description} title={product.seo.title ?? product.title}
                 pathname={"/products/" + product.urlString} />
            <Helmet
                script={[
                    helmetJsonLdProp<Product>({
                        "@context": "https://schema.org",
                        "@type": "Product",
                        "@id": product.id,
                        sku: product.id,
                        name: product.title,
                        description: product.description ?? "",
                        image: product.product_images.map((img) => product.images(img)),
                        brand: {
                            "@type": "Brand",
                            "name": "Groomd"
                        },
                        offers: {
                            "@type": "Offer",
                            "url": "https://groomd.co.in/products/" + product.urlString,
                            "priceCurrency": "INR",
                            "price": product.lowestVariation.price,
                            "itemCondition": "NewCondition",
                            "availability": "InStock",
                            "priceValidUntil": `${new Date().getFullYear()}-${new Date().setMonth(new Date().getMonth() + 1)}-${new Date().getDate()}`
                        },
                        itemCondition: "NewCondition",
                        // review: product.featured_reviews.map((r) => {
                        //     return {
                        //         "@type": "Review",
                        //         "reviewRating": {
                        //             "@type": "Rating",
                        //             "ratingValue": r.rating
                        //         },
                        //         "author": {
                        //             "@type": "Person",
                        //             "name": r.name
                        //         },
                        //         "reviewBody": r.text
                        //     }
                        // }),
                        aggregateRating: {
                            "@type": "AggregateRating",
                            "ratingValue": product.averageReview,
                            "reviewCount": product.totalReviews
                        }
                    } as WithContext<Product>)
                ]}
            />
            {size && (
                <>
                    {product.category.includes("kits-and-gifts") ?
                        (
                            <ProductGiftingTop reviewsSection={reviewsSection} size={size} transitionStatus={transitionStatus} product={product}/>
                        ) :
                        (
                            <ProductDefaultTop reviewsSection={reviewsSection} size={size} transitionStatus={transitionStatus} product={product}/>
                        )
                    }
                    {/*/!*Review Highlights*!/*/}
                    {/*{product.featured_reviews.length > 0 && (*/}
                    {/*    <section>*/}
                    {/*        <div className={"mt-8 px-4 py-4 bg-background-lighter border shadow-sm rounded-lg"}*/}
                    {/*             data-animate-fade={true}>*/}
                    {/*            <h2 className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-xs"}>*/}
                    {/*                review highlights*/}
                    {/*            </h2>*/}
                    {/*            <div className={"divide-y divide-gray-300 w-full"}>*/}
                    {/*                {product.featured_reviews.map((f_review, index) => {*/}

                    {/*                    return <div key={`${product.urlString}_f_review_${index}`}*/}
                    {/*                                className={"flex flex-col justify-center py-4"}>*/}
                    {/*                        <p className={"font-sans font-medium text-sm leading-tight opacity-80 mb-1"}>*/}
                    {/*                            {f_review.name}*/}
                    {/*                        </p>*/}
                    {/*                        <StarRating averageReview={f_review.rating} totalReviews={0}*/}
                    {/*                                    color={"#13172F"} size={14} />*/}
                    {/*                        <p className={"font-sans text-sm leading-tight mt-2"}>*/}
                    {/*                            {f_review.text}*/}
                    {/*                        </p>*/}
                    {/*                    </div>*/}

                    {/*                })}*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <FormButton textClassName={"text-sm"} onClick={() => {*/}
                    {/*                    if (reviewsSection.current) {*/}
                    {/*                        animateScroll.scrollTo(reviewsSection.current.offsetTop - 112)*/}
                    {/*                    }*/}
                    {/*                }}>*/}
                    {/*                    Read all reviews*/}
                    {/*                </FormButton>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </section>*/}
                    {/*)}*/}
                    {/*<ProductHighlight transitionStatus={transitionStatus}/>*/}
                    {product.featured_ingredients.length > 0 && (
                        <ProductFeaturedIngredients sub_items={product.subItems} is_routine={product.category.includes("routines") || product.category.includes("gifting")} title={product.featured_ingredients_title} ingredients={product.featured_ingredients} transitionStatus={transitionStatus}/>
                    )}
                    {['face-serum', 'face-moisturizer', 'face-wash'].includes(product.urlString) && (
                        <ProductHowToUse transitionStatus={transitionStatus} product={product} how_to={['face-serum', 'face-moisturizer', 'face-wash']} day_night={false}/>
                    )}
                    {['peel-off-mask'].includes(product.urlString) && (
                        <ProductHowToUse transitionStatus={transitionStatus} product={product} how_to={['peel-off-mask', 'face-moisturizer', 'face-wash']} day_night={false}/>
                    )}
                    {['skincare-indulgence-gift-set'].includes(product.urlString) && (
                        <ProductHowToUse transitionStatus={transitionStatus} product={product} how_to={['face-serum', 'face-moisturizer', 'face-wash']} day_night={false}/>
                    )}
                    {(product.category.includes("routines") && ['skin-basics', 'skin-essentials', 'skin-exfoliating-set', 'advanced-skincare-routine'].includes(product.urlString)) && (
                        <ProductHowToUse transitionStatus={transitionStatus} product={product} how_to={product.subItems.map(p => p.urlString)} day_night={false}/>
                    )}
                    {['hair-styling-clay'].includes(product.urlString) && (
                        <ProductHowToUse title={"Learn how to style"} day_night={false} transitionStatus={transitionStatus} product={product} how_to={["hair-styling-clay"]}/>
                    )}
                    <div ref={reviewsSection} />
                    <ProductReviews transitionStatus={transitionStatus} product={product} />
                    <SuggestedProducts product={product} transitionStatus={transitionStatus} />
                    <Footer transitionStatus={transitionStatus} />
                </>
            )}
        </>
    )

}

export default ProductDetails