import React, { useEffect, useState } from "react"
import { ProductItem } from "../../models/product-item"
import Review from "../../models/review"
import { collection, getDocs, limit, orderBy, query, startAfter, QueryDocumentSnapshot, DocumentData } from "firebase/firestore/lite"
import { products } from "../../utils/firebase"
import StarRating from "../../components/product-display/product-card/star-rating"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import moment from "moment"
import FormButton from "../../components/form-components/form-button"
import AnimateTexts from "../../components/animations/animate-texts"

const ProductReviews = ({product, transitionStatus}: {transitionStatus:any, product: ProductItem}) => {

    const [reviews, setReviews] = useState<Review[]>([]);
    const [last_snapshot, setLastSnap] = useState<QueryDocumentSnapshot>();
    const [finished_loaded, setLoaded] = useState(false);

    useEffect(() => {
        let q = query(collection(products, product.id, "reviews"), orderBy('created', 'desc'), limit(6))
        getDocs(q).then((snapshot) => {
            let t:any = [];
            snapshot.docs.forEach((r) => {
                t.push(new Review(r.data()))
            })
            setLastSnap(snapshot.docs[snapshot.size - 1]);
            setReviews(t);
        });
    }, [])

    const [size, setSize] = useState("");

    useEffect(() => {
        if (document.documentElement.clientWidth < 500) {
            setSize("small");
        } else {
            setSize("large")
        }
    }, []);

    const loadMore = () => {
        if (last_snapshot) {
            let q = query(collection(products, product.id, "reviews"), orderBy('created', 'desc'), startAfter(last_snapshot), limit(10))
            getDocs(q).then((snapshot) => {
                if (snapshot.empty) {
                    setLoaded(true)
                } else {
                    let t:Review[] = [...reviews];
                    snapshot.docs.forEach((r) => {
                        t.push(new Review(r.data()))
                    })
                    setLastSnap(snapshot.docs[snapshot.size - 1]);
                    setReviews(t);
                }
            });
        }
    }

    return (
        <div>
            <div className={"block sm:flex flex-wrap flex-shrink-1"} style={{ borderBottom: "1px solid rgba(150,150,150,0.3)" }}>
                <div className={"h-full block relative sm:sticky max-h-full flex-shrink-0"} style={{
                    width: size === "small" ? "100%" : "calc(50% + 2px)",
                    top: size === "small" ? "0" : "112px"
                }}>
                    <div className={"ml-auto max-w-2xl p-6 md:p-10 lg:p-12 space-y-4"}>

                        <AnimateTexts className={`highlights_section_text_sm`} transitionStatus={transitionStatus} delay={200}>
                            <div>
                                <h2 className={"font-display font-bold text-4xl sm:text-5xl max-w-3xl text-left"} data-text-animate={true}>
                                    Customer Reviews
                                </h2>
                            </div>
                        </AnimateTexts>
                        <div className={'grid grid-cols-1 sm:grid-cols-2 gap-5'}>
                            <div className={"flex items-center border rounded-xl border-gray-300 pb-4 pr-3"}>
                                <p className={"text-gray-800 font-bold font-display text-4xl md:text-5xl px-4"} >
                                    {product.averageReview.toFixed(1)}
                                </p>
                                <div className={'mt-4'}>
                                    <StarRating averageReview={product.averageReview} totalReviews={0} size={20}/>
                                    <p className={"text-base font-sans font-normal"}>
                                        {product.totalReviews} Reviews
                                    </p>
                                </div>
                            </div>
                            <div className={"flex items-center border rounded-xl border-gray-300 pb-4 pr-3"}>
                                <p className={"text-gray-800 font-bold font-display text-4xl md:text-5xl px-4"} >
                                    {product.recommendedPercentage.toFixed(0)}%
                                </p>
                                <div className={'mt-3'}>
                                    <p className={"text-base font-sans font-normal leading-tight max-w-[170px]"} >
                                        would recommend to a friend
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"min-h-0 sm:min-h-screen"} style={{
                    width: size === "small" ? "100%" : "calc(50% - 2px)",
                }}>
                    {
                        reviews.length > 0 ?
                            <div className={"max-w-2xl p-6 md:p-10 lg:py-12 space-y-4"}>
                                {
                                    reviews.map((r, index) => {
                                        if ((r.text ?? "").length > 0 || r.images.length > 0) {
                                            return <div
                                                key={product.id+'_reviews_'+index.toString()}
                                                className={"grid grid-cols-1 sm:grid-cols-4 pb-4"}
                                                style={{
                                                    borderBottom: index === reviews.length - 1 ? undefined : "1px solid rgba(150,150,150,0.3)"
                                                }}>
                                                <div>
                                                    <div className={'flex space-x-2 sm:space-x-0 sm:block'}>
                                                        <p className={"flex items-center font-bold font-sans text-sm"}>
                                                            {r.name}
                                                        </p>
                                                        <p className={"flex opacity-70 items-center font-normal font-sans text-sm"}>
                                                            Verified Buyer
                                                        </p>
                                                    </div>
                                                    <p className={"flex opacity-70 items-center font-normal font-sans text-sm mb-3"}>
                                                        {moment(r.created).format(`DD MMM YYYY`)}
                                                    </p>
                                                </div>
                                                <div className={"col-span-3"}>
                                                    <StarRating averageReview={r.rating} totalReviews={0} size={16}/>
                                                    <p className={"text-base font-sans font-normal"} style={{marginBottom: r.images.length > 0 ? "6px" : "18px", marginTop: "8px"}}>
                                                        {r.text}
                                                    </p>
                                                    {r.images.length > 0 &&
                                                    <div style={{ overflowX:"auto", WebkitOverflowScrolling: "touch", marginBottom: "18px" }}>
                                                      <div style={{ display: "-webkit-box", alignItems: "center" }}>
                                                          {
                                                              r.images.map((img) => {
                                                                  return <div style={{ width: "80px", height: "80px", background: `url("${img}") center center / cover`, overflow: "hidden", borderRadius: "6px" }}/>

                                                              })
                                                          }
                                                      </div>
                                                    </div>
                                                    }
                                                    {r.recommended &&
                                                        <div  className={"text-base font-sans font-normal flex mb-1 flex items-center"} style={{ color: "rgb(12,123,84)" }}>
                                                          <FiCheck style={{ marginRight: "6px", transform: "translateY(2px)" }} size={14} color={"rgb(21,144,101)"}/>Yes, I recommend this product
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        } else {
                                            return <></>
                                        }
                                    })
                                }
                                {(!finished_loaded || reviews.length !== product.totalReviews) && (
                                    <FormButton inverse disableArrowAnimation className={'border border-gray-300'} onClick={() => { loadMore() }}>
                                        Load more
                                    </FormButton>
                                )}
                            </div>
                            :
                            <p className={"text-sm font-sans font-normal flex mb-1"} style={{ marginBottom: "28px" }}>
                                Currently there are no reviews.
                            </p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductReviews;