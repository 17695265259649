import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ProductItem } from "../../models/product-item"
import AnimateTexts from "../../components/animations/animate-texts"
import CustomContainer from "../../components/general/contianer"
import ProductCard from "../../components/product-display/product-card/product-card"
import Flickity from 'react-flickity-component'
import "./suggested-products.css"
import AnimateProductCard from "../../components/animations/animate-product-card"
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore/lite"
import { products } from "../../utils/firebase"
import Review from "../../models/review"

const SuggestedProducts = ({product, transitionStatus }) => {

    const [productList, setProductList] = useState<ProductItem[]>([]);
    const [flickityImages, setFlickityImages] = useState<Flickity | null>(null)
    const [dragging, setDragging] = useState(false)
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {

        let q = query(products, where('category', "array-contains-any", ["routines"]), orderBy('priority'), limit(6))
        getDocs(q).then((snapshot) => {
            if (!snapshot.empty) {
                let pList: ProductItem[] = [];
                snapshot.docs.forEach(doc => {
                    let data = doc.data();
                    data.id = doc.id;
                    if (!data.disabled)
                        pList.push(new ProductItem(data));
                });
                setProductList(pList);
            } else {
                setProductList([]);
            }
        })
        .catch(e => {
            console.log(e.message)
        });

    }, [])

    useEffect(() => {
        if (flickityImages && productList && productList.length > 0) {
            setTimeout(() => {
                flickityImages.resize();
                setLoaded(true);
            }, 1000)
            flickityImages.on('dragStart', function( ) {
                setDragging(true);
            })
            flickityImages.on('dragEnd', function( ) {
                setTimeout(() => {
                    setDragging(false);
                }, 300)
            })
        }
    }, [productList, flickityImages])

    return (
        <section className={"suggested-section relative py-20 overflow-hidden"}>
            <CustomContainer className={"flex items-center justify-center mb-8 md:mb-8"}>
                <AnimateTexts className={`suggested_section_text`} transitionStatus={transitionStatus} delay={200}>
                    <h2 className={"font-display font-bold text-4xl sm:text-5xl max-w-3xl text-left"} data-text-animate={true}>
                        Go all in for your skin.
                    </h2>
                </AnimateTexts>
            </CustomContainer>
            <div className={"product_suggestions"}>
                {/*{productList.length > 0 &&*/}
                    <Flickity
                      flickityRef={el => setFlickityImages(el)}
                      options={{ friction: 0.4, prevNextButtons: false, pageDots: false, cellAlign: "center", contain: true }}
                    >
                        <div className={"w-6 sm:w-12"}/>
                            {
                                productList.map((product, index) => {
                                    return <div key={product.id} style={{ position: "absolute", width: "380px", maxWidth: "75vw", marginRight: "24px", height: loaded ? "100%" : ""}}>
                                        <ProductCard
                                            key={product.id}
                                            product={product}
                                        />
                                        {
                                            dragging && (
                                                <div className={"w-full h-full absolute top-0 left-0"}/>
                                            )
                                        }
                                    </div>
                                })
                            }
                    </Flickity>
                {/*}*/}
            </div>
        </section>
    )

}

export default SuggestedProducts;