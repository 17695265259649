import React, { useEffect, useState } from "react"
import AnimateTexts from "../../components/animations/animate-texts"
import CustomContainer from "../../components/general/contianer"
import "./suggested-products.css"
import useMediaQuery from "../../components/media-query/use-media-query"
import { HiSun } from "@react-icons/all-files/hi/HiSun"
import { HiMoon } from "@react-icons/all-files/hi/HiMoon"
import { ProductItem } from "../../models/product-item"
import { HiPlay } from "@react-icons/all-files/hi/HiPlay"
import { FiInfo } from "@react-icons/all-files/fi/FiInfo"
import CustomTransitionLink from "../../components/transition-wrapper/custom-transition-link"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import AnimateFade from "../../components/animations/animate-fade"
import { imageKitLink } from "../../utils/fomatters"

const HOW_TO_ALL =  [
    {
        item_slug: "face-wash",
        title: "Apply Face Wash",
        details: "Face Wash is the best way to start (and end!) your day. Simply wet your face with lukewarm water and gently massage in circular motions until it lightly foams. Then rinse and pat dry.",
        video: imageKitLink("/product_images_v3/face_wash_1.mov", {tr: 'f-webm,w-670,q-75,ac-none'}),
        poster: imageKitLink("/product_images_v3/face_wash_1.mov/ik-thumbnail.jpg", {tr: 'f-webp,w-670,so-8.58'}),
        video_mp4: imageKitLink("/product_images_v3/face_wash_1.mov", {tr: 'f-mp4,w-300,q-75,ac-none'}),
    },
    {
        item_slug: "peel-off-mask",
        title: "Apply Peel-Off Mask",
        details: "On a freshly cleansed face, apply an even layer of the face mask all over your face except around eyes & lips. \n\nLeave it to dry for 15-20 min, then start peeling it off from the bottom, moving upward till the entire mask is removed. Follow by gently rinsing off your face with Luke warm water.",
        video: "",
        poster: imageKitLink("/product_images_v3/peel_off_model.jpg", {tr: 'f-webp,w-670'}),
        video_mp4: "",
    },
    {
        item_slug: "face-serum",
        title: "Apply Face Serum",
        details: "Apply 2-3 drops onto the dry skin after cleansing. Use your fingertips to spread it evenly across your face & neck until fully absorbed.",
        video: imageKitLink("/product_images_v3/face_serum_1.mov", {tr: 'f-webm,w-670,q-75,ac-none'}),
        poster: imageKitLink("/product_images_v3/face_serum_1.mov/ik-thumbnail.jpg", {tr: 'f-webp,w-670,so-4.04'}),
        video_mp4: imageKitLink("/product_images_v3/face_serum_1.mov", {tr: 'f-mp4,w-300,q-75,ac-none'}),
    },
    {
        item_slug: "face-moisturizer",
        title: "Apply Face Moisturizer",
        details: "On a freshly cleansed face, gently massage a nickel-sized amount in an upward motion on the neck & face area using the fingertips till its completely absorbed.",
        video: imageKitLink("/product_images_v3/face_moisturizer_1.mov", {tr: 'f-webm,w-670,q-75,ac-none'}),
        poster: imageKitLink("/product_images_v3/face_moisturizer_1.mov/ik-thumbnail.jpg", {tr: 'f-webp,w-670,so-5.34'}),
        video_mp4: imageKitLink("/product_images_v3/face_moisturizer_1.mov", {tr: 'f-mp4,w-300,q-75,ac-none'}),
    },
    {
        item_slug: "hair-styling-clay",
        title: "Apply Hair Styling Clay",
        details: "Wash your hair and dry them up. Take a pea-sized amount, if you need more, go for it. Warm between your palms evenly apply the clay using your hands from root to tip and style your desired look.",
        video: imageKitLink("/product_images_v3/hair_clay.mov", {tr: 'f-webm,w-670,q-75,ac-none'}),
        poster: imageKitLink("/product_images_v3/hair_clay.mov/ik-thumbnail.jpg", {tr: 'f-webp,w-670,so-5.92'}),
        video_mp4: imageKitLink("/product_images_v3/hair_clay.mov", {tr: 'f-mp4,w-300,q-75,ac-none'}),
    }
];

const ProductHowToUse = ({transitionStatus, product, how_to, day_night=true, title = "Build your ideal skincare routine." }: { transitionStatus: string, product?: ProductItem, how_to: string[], day_night?: boolean, title?:string }) => {

    const [time, setTime] = useState("day");
    const [playing, setPlaying] = useState(false);
    const [active, setActive] = useState(product.urlString);

    const smDown = useMediaQuery("(max-width: 500px)")

    const updateActive = (current, future) => {
        setPlaying(false);
        let vid = document.getElementById(`video_${current.replace("-", "_")}`) as HTMLVideoElement;
        if (vid) {
            vid.pause();
            vid.currentTime = 0;
        }

        let container = document.querySelector(`.how_to_use_container_${current}`) as HTMLDivElement;
        let content = document.querySelector(`.how_to_use_container_content_${current}`) as HTMLDivElement;
        if (container) {
            container.style.height = "0px";
            container.style["WebkitMaskImage"] = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
            container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
            content.style.opacity = "0";
        }

        let container_f = document.querySelector(`.how_to_use_container_${future}`) as HTMLDivElement;
        let content_f = document.querySelector(`.how_to_use_container_content_${future}`) as HTMLDivElement;
        if (container_f) {
            container_f.style.height = content_f.clientHeight + "px";
            container_f.style["WebkitMaskImage"] = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
            container_f.style.maskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
            content_f.style.opacity = "1";
        }

        setActive(future);
    }

    const HOW_TO = HOW_TO_ALL.filter(h => how_to.includes(h.item_slug));

    useEffect(() => {
        let active_id = active;

        if (!HOW_TO.map(a => a.item_slug).includes(product.urlString)) {
            setActive(HOW_TO[0].item_slug);
            active_id = HOW_TO[0].item_slug;
        }

        let container = document.querySelector(`.how_to_use_container_${active_id}`) as HTMLDivElement;
        let content = document.querySelector(`.how_to_use_container_content_${active_id}`) as HTMLDivElement;
        if (container) {
            container.style.height = content.clientHeight + "px";
            container.style["WebkitMaskImage"] = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
            container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
            content.style.opacity = "1";
        }

    }, []);


    return (
        <section className={"product-how-to-use-section relative py-8 sm:py-24 bg-background-dark"}>
            <CustomContainer className={"grid grid-cols-1 md:grid-cols-2 gap-3 sm:gap-8"}>
                {smDown && (
                    <AnimateTexts className={`highlights_section_text_sm`} transitionStatus={transitionStatus} delay={200}>
                        <div>
                            <h2 className={"font-display font-bold text-4xl sm:text-5xl max-w-3xl text-left"} data-text-animate={true}>
                                {title}
                            </h2>
                        </div>
                    </AnimateTexts>
                )}
                <div className={'mx-9 mt-3 sm:mx-0 sm:mt-0'}>
                    <AnimateFade className={`how_to_use_video_${product.id}`} transitionStatus={transitionStatus} delay={200}>
                        <div className={"w-full aspect-w-5 aspect-h-6 bg-background-dark border-2 shadow-sm rounded-3xl overflow-hidden"} data-animate-fade={true}>
                            {smDown ? (
                                HOW_TO.map((content) => {
                                    return (
                                        (content.video_mp4 && content.item_slug === active) ?
                                            <video preload={'none'} key={`video_${content.item_slug.replace("-", "_")}`} id={`video_${content.item_slug.replace("-", "_")}`} playsInline muted className={`rounded-3xl absolute left-0 top-0 object-cover ${(content.item_slug === active) ? "opacity-100" : "opacity-0"}`}>
                                                <source src={content.video_mp4} type={"video/mp4"}/>
                                            </video>
                                            :
                                            <div key={`dummy_${content.item_slug.replace("-", "_")}`}/>
                                    )
                                })
                            ) : (
                                HOW_TO.map((content) => {
                                    return (
                                        (content.video && content.item_slug === active) ?
                                            <video preload={'none'} key={`video_${content.item_slug.replace("-", "_")}`} id={`video_${content.item_slug.replace("-", "_")}`} playsInline muted className={`rounded-3xl absolute left-0 top-0 object-cover ${(content.item_slug === active) ? "opacity-100" : "opacity-0"}`}>
                                                <source src={content.video} type={"video/webm"}/>
                                            </video>
                                            :
                                            <div key={`dummy_${content.item_slug.replace("-", "_")}`}/>
                                    )
                                })
                            )
                            }
                            {HOW_TO.map((content) => {

                                return (
                                    <div key={`poster_${content.item_slug.replace("-", "_")}`} role={"button"} className={`absolute w-full h-full flex justify-start sm:justify-center items-end sm:items-center bg-black/30 rounded-3xl ${content.item_slug === active ? "" : "pointer-events-none"}`}
                                         style={{ opacity: (playing || content.item_slug !== active) ? "0" : "100", background: `url("${content?.poster ?? ""}") center center / cover` }} onClick={() => {
                                        if (content?.video) {
                                            let vid = document.getElementById(`video_${active.replace("-", "_")}`) as HTMLVideoElement;

                                            if (vid) {
                                                if (vid.paused) {
                                                    vid.play().then(() => {
                                                        setPlaying(true)
                                                    });
                                                } else {
                                                    vid.pause();
                                                    setPlaying(false);
                                                }
                                                vid.onended = () => {
                                                    vid.currentTime = 0;
                                                    setPlaying(false);
                                                }
                                            }
                                        }
                                    }}>
                                        {
                                            content?.video && (
                                                <>
                                                    <HiPlay size={96} className={"text-white hidden sm:block"}/>
                                                    <div className={'ml-4 mb-4 bg-white space-x-2 py-1 pl-2 pr-3 rounded-full text-sm text-primary-default flex items-center font-sans font-semibold block sm:hidden'}>
                                                        <HiPlay size={20}/> <span>Play</span>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                )

                            })}
                        </div>
                    </AnimateFade>
                </div>
                <div className={"flex flex-col justify-center"}>
                    {!smDown && (
                        <AnimateTexts className={`highlights_section_text`} transitionStatus={transitionStatus} delay={200}>
                            <div>
                                <h2 className={"font-display font-bold text-4xl sm:text-5xl max-w-3xl text-left"} data-text-animate={true}>
                                    {title}
                                </h2>
                            </div>
                        </AnimateTexts>
                    )}
                    {day_night && (
                        <div className={"flex justify-start space-x-4 mt-6"}>
                            <button className={`font-medium text-base font-sans  flex items-center transition duration-200 px-4 py-1.5 mb-2 border rounded-xl border-zinc-300 hover:text-black hover:shadow-special hover:border-white hover:bg-white ${time === "day" ? "bg-white text-black border-zinc-200" : "text-gray-800 bg-background-default hover:bg-background-light"}`}
                                    onClick={() => { setTime("day"); }}
                            >
                                <HiSun className={"mr-2"} size={20}/><span>Day</span>
                            </button>
                            <button className={`font-medium text-base font-sans flex items-center transition duration-200 px-4 py-1.5 mb-2 border rounded-xl border-zinc-300 hover:text-black hover:shadow-special hover:border-white hover:bg-white ${time === "night" ? "bg-white text-black border-zinc-200" : "text-gray-800 bg-background-default hover:bg-background-light"}`}
                                    onClick={() => { setTime("night"); }}
                            >
                                <HiMoon className={"mr-2"} size={16}/><span>Night</span>
                            </button>
                        </div>
                    )}
                    {!day_night && (
                        <div className={"h-0 sm:h-6"}/>
                    )}
                    <AnimateFade className={`how_to_use_section_${product.id}`} transitionStatus={transitionStatus} delay={300}>
                        <div className={`${time==="day" ? "bg-gradient-to-b from-yellow-50 to-white" : "bg-gradient-to-b from-sky-900 to-zinc-900"} shadow-sm rounded-2xl mt-3 py-2 px-2 space-y-3`} data-animate-fade={true}>
                            {
                                HOW_TO.map((content, index) => {

                                    const bg_hover_color = time === "day" ? "hover:bg-orange-200/20" : "hover:bg-purple-200/20"
                                    const text_color = time === "day" ? "" : "text-white"
                                    const bg_highlight = time === "day" ? "bg-orange-200/20" : "bg-purple-200/20"
                                    const s_n_class = time === "day" ? "bg-zinc-800 text-white" : "bg-background-dark text-black"
                                    const border_class = time === "day" ? "border-zinc-500" : "border-background-dark"

                                    return (
                                        <div key={`content_${content.item_slug.replace("-", "_")}`} role={"button"} className={`px-3 py-3 sm:px-4 sm:py-5 rounded-xl transition duration-300 ${bg_hover_color} ${content.item_slug === active ? bg_highlight : ""}`}
                                             onClick={() => {
                                                 updateActive(active, content.item_slug)
                                             }}
                                        >
                                            <div className={`flex items-start relative duration-0 ${text_color}`}>
                                                {index !== (HOW_TO.length-1) && (
                                                    <div className={"w-px bg-gray-400 z-0 left-3.5 top-5 absolute"} style={{ height: "calc(100% + 12px)" }}/>
                                                )}
                                                {index !== 0 && (
                                                    <div className={"h-7 w-px bg-gray-400 z-0 left-3.5 -top-5 absolute"} style={{  }}/>
                                                )}
                                                <div className={`relative z-10 flex-shrink-0 ${content.item_slug === product.urlString ? "w-7 h-7" : "w-3 h-3 m-2"} flex text-sm items-center justify-center rounded-full font-medium ${s_n_class} ${content.item_slug === active ? "" : "bg-gray-400"}`}>
                                                    {content.item_slug === product.urlString && <FiCheck/>}
                                                </div>
                                                <div className={`ml-4 text-base font-semibold font-sans ${content.item_slug === active ? "" : "opacity-40"}`}>
                                                    {content.title}
                                                    <div
                                                        className={`how_to_use_container_${content.item_slug} h-0`}
                                                        style={{ willChange: "height", maskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", WebkitMaskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", transition: "height 300ms cubic-bezier(0.28, 0.13, 0.38, 1) 0s, mask-image" }}
                                                    >
                                                        <div className={`how_to_use_container_content_${content.item_slug} transition duration-300 text-sm font-sans mt-1 max-w-md`}>
                                                            <div className={"opacity-70 duration-0 whitespace-pre-wrap"}>
                                                                {content.details}
                                                            </div>
                                                            {content.item_slug !== product.urlString && (
                                                                <div className={"flex items-center space-x-3 mt-3"}>
                                                                    <CustomTransitionLink to={"/products/"+content.item_slug} className={`border ${border_class} hover:opacity-80 flex items-center rounded-full space-x-2 px-3 py-1`}>
                                                                        <FiInfo/><span>View</span>
                                                                    </CustomTransitionLink>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </AnimateFade>
                </div>
            </CustomContainer>
        </section>
    )

}

export default ProductHowToUse;