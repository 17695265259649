import React, { useEffect } from "react"

const CollapseContainer = ({id,children, open, disableFade=false, disableMask=false}: {disableMask?:boolean, disableFade?:boolean,open:boolean, id:string, children:React.ReactNode}) => {

    useEffect(() => {
        let container: HTMLDivElement = document.querySelector(`.${id}`);
        let content: HTMLDivElement = document.querySelector(`.${id}_content`);
        if (open) {
            container.style.height = content.clientHeight + "px";
            if (!disableMask) {
                container.style['WebkitMaskImage'] = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
                container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
            }
            if (!disableFade)
                content.style.opacity = "1";
        } else {
            container.style.height = "0px";
            if (!disableMask) {
                container.style['WebkitMaskImage'] = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
                container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
            }
            if (!disableFade)
                content.style.opacity = "0";
        }
    }, [open])

    return (
        <>
            <div className={`${id} w-full overflow-hidden h-0`}
                 style={{ willChange: "height", maskImage: disableMask ? '' : "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", WebkitMaskImage: disableMask ? '' : "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", transition: `height 300ms cubic-bezier(0.28, 0.13, 0.38, 1) 0s${disableMask ? '' : ', mask-image'}` }}
            >
                <div className={`${id}_content transition duration-300`}>
                    {children}
                </div>
            </div>
        </>
    )

}

export default CollapseContainer;