import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Ingredients, ProductItem, SubItems } from "../../models/product-item"
import AnimateTexts from "../../components/animations/animate-texts"
import CustomContainer from "../../components/general/contianer"
import ProductCard from "../../components/product-display/product-card/product-card"
import Flickity from 'react-flickity-component'
import "./suggested-products.css"
import AnimateProductCard from "../../components/animations/animate-product-card"
import { collection, getDocs, limit, orderBy, query, where } from "firebase/firestore/lite"
import { products } from "../../utils/firebase"
import Review from "../../models/review"
import CustomTransitionLink from "../../components/transition-wrapper/custom-transition-link"
import StarRating from "../../components/product-display/product-card/star-rating"
import ProductCardQuickAdd from "../../components/product-display/product-card/product-card-quick-add"
import useMediaQuery from "../../components/media-query/use-media-query"
import AnimateFade from "../../components/animations/animate-fade"
import CollapseContainer from "../../components/animations/collapse-general"
import { imageKitLink } from "../../utils/fomatters"

const IngredientCard = ({ingredient, sub_items}:{ ingredient: Ingredients, sub_items: SubItems[] }) => {
    return (
        <div data-animate-fade={true} className={"relative w-full h-full flex flex-col rounded-2xl overflow-hidden border border-gray-200 "}>
            <div className={"relative w-full aspect-h-5 aspect-w-5"}>
                {/*Ingredient Image*/}
                <div className={"absolute w-auto h-auto"} style={{ background: `#e7e8e3`, }}>
                    <img className={"w-full h-full absolute left-0 top-0 object-cover no-repeat"} src={imageKitLink(ingredient.image, {tr: 'f-webp,w-500'})} alt={ingredient.title} loading={"lazy"}/>
                </div>
            </div>
            <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 lg:px-6 lg:pb-6"}>
                {/*Product Title*/}
                <p className={"font-display font-medium text-xl lg:text-2xl"}>
                    {ingredient.title}
                </p>
                <p className={"font-sans text-sm lg:text-base mt-1 mr-2 opacity-60"}>
                    {ingredient.short_description}
                </p>
            </div>
            {(sub_items && sub_items.length > 0) && (
                <div className={"space-x-2 px-4 pb-4 lg:px-6 lg:pb-6 mt-auto flex flex-wrap"}>
                    {ingredient.products.map((p_id) => {
                        let product = sub_items.find(si => si.id === p_id)
                        return (
                            product ? <div key={`${ingredient.title.toLowerCase().replaceAll(" ", "_")}_${p_id}`} className={"rounded-full bg-background-dark font-medium text-sm border-2 font-sans px-3 py-[2px]"}>
                                {product?.title}
                            </div> : <div key={`${ingredient.title.toLowerCase().replaceAll(" ", "_")}_${p_id}`}/>
                        )
                    })}
                </div>
            )}
        </div>
    )
}
const ProductFeaturedIngredients = ({title, is_routine=false, ingredients, transitionStatus, sub_items }: { sub_items: SubItems[],is_routine: boolean, title: string, ingredients: Ingredients[], transitionStatus: string }) => {

    const [flickityImages, setFlickityImages] = useState<Flickity | null>(null)
    const [currentImage, setCurrentImage] = useState(0);
    const [show_more, setShowMore] = useState(false);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setLoaded(true)
        }, 200)
    }, [])

    useEffect(() => {
        if (flickityImages) {
            flickityImages.on("select", () => {
                setCurrentImage(flickityImages.selectedIndex)
            })
        }
    }, [flickityImages]);

    const smDown = useMediaQuery("(max-width: 500px)");


    return (
        <section className={"ingredients-section relative py-12 sm:py-20"}>
            <CustomContainer className={"mb-10"}>
                <AnimateTexts className={`ingredients_section_text`} transitionStatus={transitionStatus} delay={200}>
                    <h2 className={"font-display font-bold text-3xl sm:text-4xl max-w-[34rem]"} data-text-animate={true}>
                        {title.length > 1 ? (
                            <>
                                <span className={"opacity-60 mr-1"}>{title[0]}</span>{" "}<span>{title[1]}</span>
                            </>

                        ) :
                            <span>{title[0]} </span>
                        }

                    </h2>
                </AnimateTexts>
            </CustomContainer>
            {smDown!== undefined && !smDown && (
                <CustomContainer>
                    <AnimateFade className={`featured_ingredients_section`} transitionStatus={transitionStatus} delay={300} stagger={0.1}>
                        <div className={"grid grid-cols-3 gap-6"}>
                            {(ingredients.length > 3 ? (ingredients.slice(0,3)) : ingredients).map((ing, ind) => {
                                return <IngredientCard ingredient={ing} sub_items={sub_items} key={`fl_ingredients_${ind}`}/>
                            })}
                            {ingredients.length > 3 && (
                                <div className={'col-span-3'}>
                                    <CollapseContainer open={show_more} id={'featured_ingredients_more'}>
                                        <div className={"grid grid-cols-3 gap-6"}>
                                            {ingredients.slice(3,ingredients.length).map((ing, jnd) => {
                                                return <IngredientCard ingredient={ing} sub_items={sub_items} key={`fl_ingredients_${jnd+3}`}/>
                                            })}
                                        </div>
                                    </CollapseContainer>
                                </div>
                            )}
                            {ingredients.length > 3 ? (
                                <div className={'flex col-span-3 justify-center w-full'}>
                                    <button className={'rounded-xl bg-background-dark font-sans font-medium px-4 py-2 text-sm border border-gray-300 hover:bg-background-default transition duration-300'} onClick={() => { setShowMore(s => !s); }}>{show_more ? 'Hide' : `Show ${(ingredients.length - 3)} more` }</button>
                                </div>

                            ) : <div/>}
                        </div>
                    </AnimateFade>
                </CustomContainer>
            )}
            {smDown!== undefined && smDown && (
                <AnimateFade className={`featured_ingredients_section_small`} transitionStatus={transitionStatus} delay={300} stagger={0.1}>
                    <div className={"ingredient_slider"}>
                        <Flickity
                            flickityRef={el => setFlickityImages(el)}
                            options={{ friction: 0.4, prevNextButtons: false, pageDots: false, cellAlign: "left" }}
                        >
                            <div className={"w-6 h-full flex-shrink-0"}/>
                            {ingredients.map((ingredient, ind) => {
                                return (
                                    <div key={`f_ingredients_${ind}`} className={"relative flex mr-6"} style={{ width: "calc(100vw - 76px)", height: loaded ? '100%' : '' }} data-animate-fade={true}>
                                        <div className={"relative h-full flex flex-col rounded-2xl overflow-hidden border border-gray-200"}>
                                            <div className={"relative w-full aspect-h-5 aspect-w-5"}>
                                                {/*Ingredient Image*/}
                                                <div className={"absolute w-auto h-auto"} style={{ background: `#e7e8e3`, }}>
                                                    <img className={"w-full h-full absolute left-0 top-0 object-cover no-repeat"} src={imageKitLink(ingredient.image)} alt={ingredient.title} loading={"lazy"}/>
                                                </div>
                                            </div>
                                            <div style={{ background: "linear-gradient(rgba(231,232,227,1), rgba(231,232,227,0))" }} className={"px-4 pb-4 sm:px-6 sm:pb-6"}>
                                                {/*Product Title*/}
                                                <p className={"font-display font-medium text-xl sm:text-2xl"}>
                                                    {ingredient.title}
                                                </p>
                                                <p className={"font-sans text-sm sm:text-base mt-1 mr-2 opacity-60"}>
                                                    {ingredient.short_description}
                                                </p>
                                            </div>
                                            {is_routine && (
                                                <div className={"space-x-2 px-4 pb-4 lg:px-6 lg:pb-6 mt-auto flex flex-wrap"}>
                                                    {ingredient.products.map((p_id) => {
                                                        let product = sub_items.find(si => si.id === p_id)
                                                        return (
                                                            product ? <div key={`f_small_ingredients_${ind}_${p_id}`} className={"rounded-full bg-background-dark font-medium text-sm border-2 font-sans px-3 py-[2px]"}>
                                                                {product?.title}
                                                            </div> : <div/>
                                                        )
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )
                            })}
                        </Flickity>
                    </div>
                </AnimateFade>
            )}
        </section>
    )

}

export default ProductFeaturedIngredients;