export default class Review {
    id: string = "";
    name: string = "";
    rating: number = 0;
    text: string = "";
    images: string[] = [];
    recommended: boolean = true;
    created: Date = new Date();
    uid: string = "";
    product_id: string = "";

    constructor(data?: {[field: string]: any}) {
        this.id = data?.id ?? "";
        this.name = data?.name ?? "";
        this.rating = data?.rating ?? 0;
        this.text = data?.text ?? "";
        this.images = data?.images ?? [];
        this.recommended = data?.recommended ?? true;
        this.created = data?.created ? (typeof data?.created === "string" ? new Date(data.created) : (data.created instanceof Date ? data.created : new Date(data.created.toDate().toString()))) : new Date();
        this.uid = data?.uid ?? "";
        this.product_id = data?.product_id ?? "";
    }

    toJson(): Review {
        return Object.assign({}, this);
    }
}
