import React, { useEffect, useState } from "react"
import { ProductItem } from "../../models/product-item"
import { addItem } from "../../redux/actions/cart/cart-actions"
import { useDispatch } from "react-redux"
import { Dispatch } from "redux"
import { CartActionTypes } from "../../redux/types/cart-type"
import { createCurrencyText, validateCoupon } from "../../utils/fomatters"
import { FiMail } from "@react-icons/all-files/fi/FiMail"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { promo_codes } from "../../utils/firebase"
import { getDocs, query, where } from "firebase/firestore/lite"
import Discount from "../../models/discount"
import DiscountIcon from "../../images/Discount.svg"
import copy from "copy-to-clipboard";
import CollapseContainer from "../../components/animations/collapse-general"
import { FiPlus } from "@react-icons/all-files/fi/FiPlus"
import { FiMinus } from "@react-icons/all-files/fi/FiMinus"
import { FiCopy } from "@react-icons/all-files/fi/FiCopy"

const ProductDetailsQuickAdd = ({product}: {product: ProductItem}) => {

    const [variationSelected, setVariationSelected] = useState(product.lowestVariation.tag);
    const [error, setError] = useState("");
    const [added, setAdded] = useState(false);

    const [valid_promos, setValidPromos] = useState<Discount[]>([]);
    const [show_more_promos, setShowMorePromos] = useState<boolean>(false);
    const dispatch = useDispatch<Dispatch<CartActionTypes>>();

    let currentMrp: number = (product.variation.find((v) => v.tag === variationSelected) ?? product.lowestVariation).mrp;
    let currentPrice: number = (product.variation.find((v) => v.tag === variationSelected) ?? product.lowestVariation).price;

    useEffect(() => {
        getDocs(query(promo_codes, where('public', '==', true))).then((snap) => {
            if (!snap.empty) {
                let valid_promos: Discount[] = [];
                let promotions: Discount[] = snap.docs.map(s => new Discount({ ...s.data(), id: s.id }));
                promotions.forEach(p => {
                    if (validateCoupon(product.id, product.category, p)) {
                        valid_promos.push(p);
                    }
                })
                valid_promos.sort((a,b) => b.discount - a.discount)
                setValidPromos(valid_promos);
            }
        })
    }, []);


    const PromotionCard = ({promotion, is_first, is_last}: {promotion: Discount, is_first:boolean, is_last: boolean}) => {

        const [copied, setCopied] = useState(false);

        return (
            <div className={`transition duration-300 flex items-start bg-gradient-to-b from-background-lighter to-[#fdfbf3] ${is_first ? 'rounded-t-[16px]' : ''} ${is_last ? 'rounded-b-[16px]' : ''} px-4 pr-2.5 py-2.5`}>
                <div className={'mr-auto'}>
                    <div className={"flex items-start"}>
                        <img alt={'Discount Icon'} src={DiscountIcon} className={'w-6 text-emerald-600 mr-2 mt-1'}/>
                        <div className={'mt-1'}>
                            <div className={'flex mb-1.5'}>
                                <div className={'text-sm font-sans font-semibold text-green-700 bg-green-700/10 px-3 py-[2px] rounded-full'}>
                                    Save {promotion.discount*100}%
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className={'text-sm font-sans font-semibold text-emerald-700 leading-[1.1] mt-[2px]'}>
                        Get it for {createCurrencyText((currentPrice - (currentPrice*promotion.discount)), {})}
                    </div>
                    <p className={"text-gray-800 text-sm font-sans font-normal opacity-80 mt-px"}>
                        {promotion.description}
                    </p>
                </div>
                <button onClick={() => {
                    copy(promotion.code);
                    setCopied(true);
                    setTimeout(() => {
                        setCopied(false)
                    }, 1200)
                }} className={"relative shadow-inner rounded-xl bg-white/75 border border-zinc-200 py-[3px] px-3 ml-4 cursor-pointer flex-shrink-0"} >
                    <div>
                        <div className={"font-sans text-xs font-light opacity-80 tracking-widest text-right"}>
                            USE CODE
                        </div>
                        <div className={"flex items-center -mt-1 font-bold text-base font-sans text-right text-green-700"}>
                            <FiCopy size={10} className={'mr-1.5 text-zinc-600'}/>
                            <span>
                                {promotion.code}
                            </span>
                        </div>
                        <div className={`font-medium font-sans text-green-700 absolute left-1/2 transform -translate-x-1/2 translate-y-1.5 text-sm transition duration-300 ${copied ? "opacity-100" : "opacity-0"}`}>
                            Copied!
                        </div>
                    </div>
                </button>
            </div>
        )
    }

    return (
        <>
            {valid_promos.length > 0 && (
                <div className={'rounded-[17px] p-[1px] pr-[0.5px] mb-4 hover:shadow-special transition duration-300'} style={{ background: "linear-gradient(90deg,rgb(162,210,190),rgb(212,240,223),rgb(180,230,190),rgb(223,245,240),rgb(174,240,212))" }}>
                    {
                        valid_promos.slice(0,1).map((promotion, index) => {

                            const is_first = index === 0;
                            const is_last = index === (valid_promos.length - 1)

                            return (
                                <div className={'mb-px'}>
                                    <PromotionCard promotion={promotion} is_first={is_first} is_last={is_last}/>
                                </div>
                            )

                        })
                    }
                    {
                        valid_promos.length > 1 && (
                            <CollapseContainer disableMask disableFade open={show_more_promos} id={`${product.id}_promos`}>
                                {
                                    valid_promos.slice(1).map((promotion, index) => {

                                        const is_first = (index+1) === 0;

                                        return (
                                            <div className={'pb-px sm:pb-[2px]'}>
                                                <PromotionCard promotion={promotion} is_first={is_first} is_last={false}/>
                                            </div>
                                        )

                                    })
                                }
                            </CollapseContainer>
                        )
                    }
                    {valid_promos.length > 1 && (
                        <button className={`bg-gradient-to-b from-background-light to-[#fdfbf3] rounded-b-[16px] py-1.5 w-full text-sm font-sans flex items-center justify-center text-zinc-500 font-medium`} onClick={() => { setShowMorePromos(p => !p) }}>
                            {!show_more_promos ?
                                (
                                    <FiPlus className={'mr-2'}/>
                                )
                                :  (
                                    <FiMinus className={'mr-2'}/>
                                )
                            }
                            <span>
                                {show_more_promos ? 'Hide' : `${valid_promos.length-1} more`}
                            </span>
                        </button>
                    )}
                </div>
            )}
            {error &&
                <p className={"text-sm font-sans text-red-700"}>
                    {error}
                </p>
            }
            <div className={`flex flex-col items-start w-full mt-1 ${product.variation.length > 1 ? "mb-6" : "mb-1"}`}>
                {product.variation.length > 1 &&
                product.variation.map((v) => {
                    return <button key={v.tag}
                                   className={`h-14 w-full flex items-center transition duration-200 px-6 py-4 mb-2 border rounded-xl border-zinc-300 hover:text-black hover:shadow-special hover:border-white hover:bg-white ${v.tag === variationSelected ? "bg-white text-black border-zinc-200" : "text-gray-800 bg-background-default hover:bg-background-light"}`}
                                   onClick={() => { setVariationSelected(v.tag); setError("") }}
                    >
                        <div className={`w-2 h-2 rounded-full ${v.tag === variationSelected ? "bg-primary-lighter" : "bg-zinc-300"} mr-3`}/>
                        <p className={"font-medium text-sm font-sans"}>
                            {v.title}
                        </p>
                        {(v.mrp && v.mrp > v.price) && (
                            <p className={"font-semibold text-sm font-sans ml-3 text-green-700 bg-green-700/10 px-3 py-1 rounded-full"}>
                                Save {((v.mrp - v.price)/v.mrp * 100).toFixed(0)}%
                            </p>
                        )}
                        <p className={"font-medium text-sm font-sans ml-auto"}>
                            {createCurrencyText(v.price, {})}
                        </p>
                        {(v.mrp && v.mrp > v.price) && (
                            <p className={"font-medium text-sm font-sans line-through ml-2 opacity-60"}>
                                {createCurrencyText(v.mrp, {})}
                            </p>
                        )}
                    </button>
                })
                }
            </div>
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    if (product.out_of_stock) {

                    } else {
                        if (product.variation.length === 1) {
                            dispatch(addItem(product, (product.lowestVariation.tag)));
                            setAdded(true);
                            setTimeout(() => {
                                document.getElementById("cart_dialog_button").click()
                            }, 150)
                        } else {
                            if (variationSelected) {
                                dispatch(addItem(product, (product.variation.find((v) => v.tag === variationSelected)?.tag ?? product.lowestVariation.tag)))
                                setAdded(true);
                                setTimeout(() => {
                                    document.getElementById("cart_dialog_button").click()
                                }, 150)
                            } else {
                                setError("Please select a size.")
                            }
                        }
                        setTimeout(() => {
                            setAdded(false);
                        }, 750)
                    }
                }}
                className={"group relative overflow-hidden w-full px-6 py-3.5 transition duration-300 rounded-2xl border border-gray-300 hover:border-gray-600 hover:shadow-inner hover:bg-primary-lighter text-background-default flex justify-between bg-primary-default"}>
                <div aria-hidden className={`flex items-center justify-center bg-green-800 w-full h-full absolute left-0 top-0 space-x-2 transition duration-200 ${added ? "opacity-100" : "opacity-0 pointer-events-none"}`} style={{ zIndex: 1 }}>
                    <FiCheck size={14} strokeWidth={3} className={"text-white"}/>
                    <p className={"hidden sm:inline-flex font-sans text-white font-medium text-base"}>
                        Added
                    </p>
                </div>
                {
                    product.out_of_stock ?
                        <div className={"flex flex-row-reverse sm:flex-row items-center justify-between w-full"}>
                            <p className={"font-sans font-medium text-base"}>
                                Notify <span className={"hidden lg:inline-flex"}>when available</span>
                            </p>
                            <FiMail size={14}/>
                        </div>
                        :
                        <div className={"flex items-center justify-between w-full"}>
                            <p className={"inline-flex font-sans font-medium text-base"}>
                                Add to Cart
                            </p>
                            <p className={"font-sans font-bold text-base"}>
                                {createCurrencyText(currentPrice, {})}
                                {!variationSelected && product.variation.length > 1 &&
                                        "+"
                                }
                                {(variationSelected || product.variation.length == 1) && currentPrice < currentMrp &&
                                    <span className={"line-through pl-2 opacity-60 font-medium"}>
                                        {createCurrencyText(currentMrp, {})}
                                    </span>
                                }
                            </p>
                        </div>
                }
            </button>
        </>
    )

}

export default ProductDetailsQuickAdd;