import AnimateTexts from "../../components/animations/animate-texts"
import AnimateFade from "../../components/animations/animate-fade"
import StarRating from "../../components/product-display/product-card/star-rating"
import { animateScroll } from "react-scroll"
import { promises } from "../../utils/constants"
import ProductDetailsQuickAdd from "./product-details-quick-add"
import Flickity from "react-flickity-component"
import { FiArrowLeft } from "@react-icons/all-files/fi/FiArrowLeft"
import { FiArrowRight } from "@react-icons/all-files/fi/FiArrowRight"
import CustomTransitionLink from "../../components/transition-wrapper/custom-transition-link"
import React, { MutableRefObject, useEffect, useState } from "react"
import { ProductItem } from "../../models/product-item"
import ProductDefaultTop from "./product-default-top"
import { FiCheck } from "@react-icons/all-files/fi/FiCheck"
import { FiChevronDown } from "@react-icons/all-files/fi/FiChevronDown"
import { imageKitLink } from "../../utils/fomatters"

const ProductGiftingTop = ({size, product, transitionStatus, reviewsSection}: {product: ProductItem,transitionStatus: string, size: string, reviewsSection: MutableRefObject<HTMLDivElement>}) => {

    const [flickityImages, setFlickityImages] = useState<Flickity | null>(null)
    const [currentImage, setCurrentImage] = useState(0)


    const goTo = (index) => {
        flickityImages?.select(index)
    }
    const goNext = () => {
        flickityImages?.next()
    }
    const goPrevious = () => {
        flickityImages?.previous()
    }

    useEffect(() => {
        if (flickityImages) {
            flickityImages.on("select", () => {
                setCurrentImage(flickityImages.selectedIndex)
            })
        }
    }, [flickityImages])

    return (
        <>
            <div className={'w-full'} style={{ minHeight: 'calc(100vh + 24px)' }}>
                <div className={"grid grid-cols-1 md:grid-cols-5"}>
                    <div className={"w-full max-h-screen md:sticky col-span-1 md:col-span-3"} style={{  marginTop: size === "small" ? "112px" : "0", top: size === "small" ? "" : "112px", height: size === "small" ? "80vw" : "calc(100vh - 112px)" }}>
                        <AnimateFade style={{ height: "calc(100% - 3rem)" }} rootClass={"relative"} className={`${product.urlString}_product_images`} transitionStatus={transitionStatus} delay={size === "small" ? 0 : 100} stagger={0.1}>
                            <div className={"images relative m-4 sm:m-6 rounded-2xl overflow-hidden"} style={{ height: "calc(100%)" }} data-animate-fade={true}>
                                <Flickity
                                    flickityRef={el => setFlickityImages(el)}
                                    options={{ friction: 0.4, prevNextButtons: false, pageDots: false, cellAlign: "center", lazyLoad: 1 }}
                                >
                                    {
                                        product?.product_images.map((image, index) => {

                                            const transparent = image.includes("material") || image.includes("flat") || image.includes("routine");

                                            return (
                                                <div key={`main_image_${index}`} className={"rounded-2xl mr-6 border border-zinc-200 w-full h-full overflow-hidden"} style={{ background: `#e7e8e3` }} >
                                                    <img data-flickity-lazyload={product.images(image)} alt={`${product.title} ${index}`} className={`product_image rounded-2xl w-full h-full ${transparent ? "object-contain" : "object-cover"}`} />
                                                </div>
                                            )
                                        })
                                    }
                                </Flickity>
                                {(product?.product_images?.length ?? 0) > 1 && (
                                    <div style={{
                                        position: "absolute",
                                        mixBlendMode: "difference",
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        left: size === "small" ? "0" : "24px",
                                        padding: "24px",
                                        opacity: currentImage > 0 ? 1 : "0.2"
                                    }} onClick={() => {
                                        goPrevious()
                                    }}>
                                        <FiArrowLeft size={24} color={"antiquewhite"} />
                                    </div>
                                )}

                                {(product?.product_images?.length ?? 0) > 1 && (
                                    <div style={{
                                        position: "absolute",
                                        top: "50%",
                                        mixBlendMode: "difference",
                                        transform: "translateY(-50%)",
                                        right: size === "small" ? "0" : "24px",
                                        padding: "24px",
                                        opacity: currentImage >= ((product?.product_images?.length ?? 0) - 1) ? 0.2 : 1
                                    }} onClick={() => {
                                        goNext()
                                    }}>
                                        <FiArrowRight size={24} color={"antiquewhite"} />
                                    </div>
                                )}

                                {(product?.product_images?.length ?? 0) > 1 && (
                                    <div className={"rounded-full absolute"}
                                         style={{
                                             bottom: size==="small" ? "4px" : "24px",
                                             left: "50%",
                                             transform: "translateX(-50%)",
                                             padding: "12px 16px"
                                         }}
                                    >
                                        <div className={"flex items-center justify-center bg-white/20 backdrop-blur-lg backdrop-saturate-100 rounded-full py-1 px-1.5 md:py-1.5 md:px-2"}>
                                            {product?.product_images.map((p_image, index) => {
                                                return (
                                                    <div
                                                        onClick={() => { goTo(index) }}
                                                        key={`main_image_indicator_${index}`}
                                                        className={`cursor-pointer rounded-full hover:ring-gray-100 p-1 md:p-1.5`}

                                                    >
                                                        <div className={`w-2 h-2 cursor-pointer rounded-full hover:ring-gray-100`}
                                                             style={{
                                                                 background: currentImage === index ? "rgb(0,0,0)" : "rgba(0,0,0,0.4)"
                                                             }}
                                                        />
                                                    </div>
                                                )
                                            })

                                            }
                                        </div>
                                    </div>
                                )}
                            </div>
                        </AnimateFade>
                    </div>
                    <div className={"col-span-1 md:col-span-2 px-6 py-0 md:py-20 min-h-0 md:min-h-screen"} style={{ marginTop: size === "small" ? "0" : "64px" }}>
                        <div className={"w-full md:max-w-md"}>
                            <div className={"grid sm:grid-cols-2 sm:gap-6 md:gap-0 md:grid-cols-1"}>
                                {/*Product Header*/}
                                <div>
                                    {/*<div className={'bg-red-700 rounded-2xl mb-4 py-2'}>*/}
                                    {/*    <AnimateFade className={"valentine_text"} transitionStatus={transitionStatus} delay={100} targetOpacity={1}>*/}
                                    {/*        <img alt={'A Perfect Valentine Gift'} src={imageKitLink('/product_images_v3/v_texts_4.png', { tr: 'w-600,f-webp' })} data-animate-fade={true} className={'w-full px-12'}/>*/}
                                    {/*    </AnimateFade>*/}
                                    {/*</div>*/}
                                    <section>
                                        <AnimateTexts className={`${product.urlString}_product_header`} transitionStatus={transitionStatus} delay={size === "small" ? 50 : 150} stagger={0.2}>
                                            {/*Category*/}
                                            <p
                                                className={"text-gray-700 font-semibold font-sans uppercase tracking-widest text-xs mb-1"}
                                                data-text-animate={true}>
                                                {product?.categoryTitle}
                                            </p>
                                            {/*Title*/}
                                            <h1 className={"font-display font-bold text-4xl sm:text-5xl mb-2"} data-text-animate={true}>
                                                {product?.title}
                                            </h1>
                                        </AnimateTexts>
                                        {/*Size & Rating*/}
                                        <AnimateFade className={`${product.urlString}_product_rating_summary`} transitionStatus={transitionStatus} delay={size === "small" ? 100 : 250}>
                                            <div className={"flex items-center"} data-animate-fade={true}>
                                                {product?.size && (
                                                    <>
                                                        <p className={"font-normal text-sm font-sans transform translate-y-px opacity-60"}>
                                                            {product?.size}
                                                        </p>
                                                        <div className={"mx-4 w-4 h-px bg-gray-600 "} />
                                                    </>
                                                )}
                                                <div className={"border-b border-transparent hover:border-zinc-300 transition duration-300 cursor-pointer pb-1"} style={{ transform: "translateY(12%)" }}>
                                                    <StarRating
                                                        size={16}
                                                        color={"rgb(234,180,49)"}
                                                        textClassName={"opacity-50"}
                                                        averageReview={product.averageReview}
                                                        totalReviews={product.totalReviews}
                                                        onClick={() => {
                                                            if (reviewsSection.current) {
                                                                animateScroll.scrollTo(reviewsSection.current.offsetTop - 112)
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </AnimateFade>
                                    </section>

                                    <AnimateFade className={`${product.urlString}_product_body`} transitionStatus={transitionStatus} delay={size === "small" ? 100 : 500} stagger={0.1}>
                                        {/*List of Benefits*/}
                                        <div className={'text-base font-sans mt-4'}>
                                            An ideal gift set for
                                            every occasion
                                            Skincare Indulgence Gift Set is specially curated to
                                            address all skincare problems through a selection of
                                            products that your skin will fall in love with. These
                                            handpicked products come packed in an elegant box,
                                            making it the perfect gift for all occasions.
                                        </div>
                                        {product.benefits.length > 0 && (
                                            <section>
                                                <div className={"pt-8"} data-animate-fade={true}>
                                                    <h2 className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-xs mb-2"}>
                                                        Benefits
                                                    </h2>
                                                    <div className={"space-y-1"}>
                                                        {product?.benefits.map((benefit, index) => {
                                                            return <div className={"flex items-start"} key={index}>
                                                                <FiCheck color={"rgb(40,40,40)"} className={"flex-shrink-0 mr-3 "}
                                                                         style={{ transform: "translateY(50%)" }} size={15} />
                                                                <p className={"font-sans text-sm"}>
                                                                    {benefit}
                                                                </p>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </section>
                                        )}

                                        {/*Contains*/}
                                        {product.subItems.length > 0 && (
                                            <section>
                                                <div className={"pt-8"} data-animate-fade={true}>
                                                    <h2 className={"text-gray-800 font-semibold font-sans uppercase tracking-widest text-xs mb-3"}>
                                                        Contains:
                                                    </h2>
                                                    <div className={"w-full grid grid-cols-4 gap-2 md:gap-3"}>
                                                        {product?.subItems.map((i) => {
                                                            return <div key={`subitem_${i.urlString}`}>
                                                                <CustomTransitionLink to={`/products/${i.urlString}`}>
                                                                    <div
                                                                        className={"group relative flex flex-col justify-center items-start"}>
                                                                        <div
                                                                            style={{ background: `url("${i.thumbnail_src}") center center / cover` }}
                                                                            className={"w-16 h-16 rounded-xl transform transition duration-200 group-hover:-translate-y-1"} />
                                                                        <p className={"text-xs font-medium text-gray-800 font-sans pt-2"}>
                                                                            {i.title}
                                                                        </p>
                                                                        <div className={"flex pb-2"}>
                                                                            <p className={"font-serif font-normal text-xs"}>
                                                                                <i>{i?.size}</i>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </CustomTransitionLink>
                                                            </div>
                                                        })}
                                                    </div>
                                                </div>
                                            </section>
                                        )}

                                        {/*/!*Add To Cart Button (Mobile)*!/*/}
                                        {/*{size === "small" && (*/}
                                        {/*    <div*/}
                                        {/*        className={"fixed bottom-0 left-0 right-0 px-4 pt-3 transition duration-300 delay-150 ease-in-out border-t border-zinc-300 bg-background-light"}*/}
                                        {/*        style={{*/}
                                        {/*            zIndex: 1000,*/}
                                        {/*            paddingBottom: "1.5rem",*/}
                                        {/*            transform: (transitionStatus === "entered" && scrollPastReviews && !scrollPastStart) ? "translateY(100%)" : "translateY(0)"*/}
                                        {/*        }}>*/}
                                        {/*        <ProductDetailsQuickAdd product={product} />*/}
                                        {/*    </div>*/}
                                        {/*)}*/}

                                        {/*Add To Cart Button*/}
                                        <section>
                                            <div className={"pt-4 pb-6 mt-6 border-t border-b sm:border-b-0 md:border-b border-zinc-300"} data-animate-fade={true}>
                                                <ProductDetailsQuickAdd product={product} />
                                            </div>
                                        </section>
                                    </AnimateFade>
                                </div>
                                <AnimateFade className={`${product.urlString}_product_body_2`} transitionStatus={transitionStatus} delay={size === "small" ? 100 : 700} stagger={0.1}>
                                    {/*Good to Know*/}
                                    {product.good_to_know.length > 0 && (
                                        <section data-animate-fade={true}>
                                            <button
                                                className={`flex justify-between items-center w-full py-6 px-2 text-base font-sans text-left text-gray-900 bg-background-default focus-visible:bg-background-dark`}
                                                onClick={() => {
                                                    let container = document.querySelector(".container_0");
                                                    let h = container.clientHeight;
                                                    let content = document.querySelector(".container_0_content");
                                                    let arrow = document.querySelector(".expand_arrow_0");
                                                    if (h === 0) {
                                                        container.style.height = content.clientHeight + "px";
                                                        container.style.WebkitMaskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
                                                        container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)";
                                                        content.style.opacity = 1;
                                                        arrow.style.transform = "rotate(180deg)";
                                                    } else {
                                                        container.style.height = "0px";
                                                        container.style.WebkitMaskImage = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
                                                        container.style.maskImage = "linear-gradient(rgb(0, 0, 0) 1px, transparent 100%)";
                                                        content.style.opacity = 0;
                                                        arrow.style.transform = "rotate(0deg)";
                                                    }
                                                }}
                                            >
                                                <p className={"text-gray-800 font-semibold font-sans text-sm"} >
                                                    Highlights
                                                </p>
                                                <FiChevronDown className={"expand_arrow_0"} size={24} style={{ transform: "rotate(180deg)", transition: "transform 300ms cubic-bezier(0.28, 0.13, 0.38, 1) 0s" }}/>
                                            </button>
                                            <div className={"container_0 font-sans text-sm text-gray-700 px-2 overflow-hidden border-b border-zinc-300"}
                                                 style={{ height: (document.querySelector(".container_0_content")?.clientHeight ?? 0)+"px", willChange: "height", maskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", WebkitMaskImage: "linear-gradient(rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%)", transition: "height 300ms cubic-bezier(0.28, 0.13, 0.38, 1) 0s, mask-image" }}
                                            >
                                                <div className={"container_0_content transition duration-300 pb-6 leading-6"}>
                                                    <div className={"space-y-5"}>
                                                        {
                                                            product.good_to_know.map((p) => {

                                                                let promise = promises.find((pr) => pr.id === p)
                                                                if (promise) {
                                                                    return <div key={promise.alt} className={"flex items-center space-x-3"}>
                                                                        <img src={promise.image} alt={promise.alt} className={"w-5"} />
                                                                        <p className={"font-sans text-black font-medium text-sm text-center"}>
                                                                            {promise.caption}
                                                                        </p>
                                                                    </div>
                                                                } else {
                                                                    return <div/>
                                                                }

                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                    )}
                                </AnimateFade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={"min-h-screen"} style={{ background: `url("${size === "small" ? "https://ik.imagekit.io/groomd/product_images_v3/gifting_bg_mobile_3.jpg?tr=f-webp,w-600" : "https://ik.imagekit.io/groomd/product_images_v3/gifting_background.jpg?tr=f-webp,w-1900"}") center bottom / cover` }}>*/}
            {/*    <div className={"absolute top-36 left-1/2 transform -translate-x-1/2 w-full max-w-[450px] px-2"}>*/}
            {/*        <AnimateTexts className={`${product.urlString}_product_header`} transitionStatus={transitionStatus} delay={size === "small" ? 50 : 150} stagger={0.2}>*/}
            {/*            /!*Category*!/*/}
            {/*            <p*/}
            {/*                className={"text-center text-gray-700 font-semibold font-sans uppercase tracking-widest text-xs mb-1"}*/}
            {/*                data-text-animate={true}>*/}
            {/*                {product?.categoryTitle}*/}
            {/*            </p>*/}
            {/*            /!*Title*!/*/}
            {/*            <h1 className={"text-center font-display font-bold text-4xl sm:text-5xl mb-2"} data-text-animate={true}>*/}
            {/*                {product?.title}*/}
            {/*            </h1>*/}
            {/*            <p className={"font-sans text-base sm:text-lg mt-4 mb-1 text-center text-zinc-500"} data-text-animate={true}>*/}
            {/*                Indulge them with the art of self-care.*/}
            {/*            </p>*/}
            {/*        </AnimateTexts>*/}
            {/*        /!*Size & Rating*!/*/}
            {/*        <AnimateFade className={`${product.urlString}_product_rating_summary`} transitionStatus={transitionStatus} delay={size === "small" ? 100 : 250}>*/}
            {/*            <div className={"flex items-center justify-center"} data-animate-fade={true}>*/}
            {/*                {product?.size && (*/}
            {/*                    <>*/}
            {/*                        <p className={"font-normal text-sm font-sans transform translate-y-px opacity-60"}>*/}
            {/*                            {product?.size}*/}
            {/*                        </p>*/}
            {/*                        <div className={"mx-4 w-4 h-px bg-gray-600 "} />*/}
            {/*                    </>*/}
            {/*                )}*/}
            {/*                <div className={"border-b border-transparent hover:border-zinc-300 transition duration-300 cursor-pointer pb-1"} style={{ transform: "translateY(12%)" }}>*/}
            {/*                    <StarRating*/}
            {/*                        size={16}*/}
            {/*                        color={"rgb(193,142,19)"}*/}
            {/*                        textClassName={"opacity-50"}*/}
            {/*                        averageReview={product.averageReview}*/}
            {/*                        totalReviews={product.totalReviews}*/}
            {/*                        onClick={() => {*/}
            {/*                            if (reviewsSection.current) {*/}
            {/*                                animateScroll.scrollTo(reviewsSection.current.offsetTop - 112)*/}
            {/*                            }*/}
            {/*                        }}*/}
            {/*                    />*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div className={"flex flex-wrap justify-center mt-3"} data-animate-fade={true}>*/}
            {/*                {*/}
            {/*                    product.good_to_know.map((p) => {*/}

            {/*                        let promise = promises.find((pr) => pr.id === p)*/}
            {/*                        if (promise) {*/}
            {/*                            return <div key={promise.alt} className={"mr-3 mt-3 flex flex-shrink-0 items-center space-x-2 sm:space-x-3 px-2 sm:px-2.5 py-1 rounded-full bg-white/40"}>*/}
            {/*                                <img src={promise.image} alt={promise.alt} className={"w-3"} />*/}
            {/*                                <p className={"font-sans text-black font-medium text-xs text-center"}>*/}
            {/*                                    {promise.caption}*/}
            {/*                                </p>*/}
            {/*                            </div>*/}
            {/*                        } else {*/}
            {/*                            return <div/>*/}
            {/*                        }*/}

            {/*                    })*/}
            {/*                }*/}
            {/*            </div>*/}
            {/*            <div className={"pt-4 pb-6 mt-5 border-t border-zinc-300 max-w-[250px] mx-auto"} data-animate-fade={true}>*/}
            {/*                <ProductDetailsQuickAdd product={product} />*/}
            {/*            </div>*/}
            {/*        </AnimateFade>*/}
            {/*    </div>*/}


            {/*</div>*/}
            {/*<ProductDefaultTop reviewsSection={reviewsSection} size={size} transitionStatus={transitionStatus} product={product}/>*/}
        </>
    )
}

export default ProductGiftingTop;